


import {
    Component,
    Vue,
    Prop,
    Watch
} from "vue-property-decorator";
import Axios from "axios";
import saveAs from "save-as";
import {
    BASE_API_URL
} from "@/config";
import {
    LOAN_ESTIMATE_OTHER_FIELDS,
} from "@/constants";
import Vue2Filters from 'vue2-filters';
import SaveInformationMixin from "@/mixins/SaveInformationMixin";
import _ from "lodash";
import {mixins} from "vue-class-component";
import LOSMixin from "@/mixins/los.mixin";

Vue.use(Vue2Filters)

@Component
export default class LoanEstimate extends mixins(LOSMixin, SaveInformationMixin) {
    @Prop() public saveCallback;
    @Prop() public errorCallback;
    @Prop() public fromView;
    @Prop({ default: false }) public readOnlyLoanEstimate;

    @Prop()
    public loanIsLocked;

    public borrowerInfo: any = {};

    public feesIndex = null;
    public toggleActiveIndex: any = null;
    public details = {
        buyerAmount: null,
        sellerAmount: null,
        totalAmount: null,
        type: null,
        feeDetails: []
    };
    public isDraft = false;
    public companyDetails = {};
    public sectionFields = [];
    public currentSection = '';
    public fieldsToAdd = [];
    public pathToAddFields: any = [];
    public amountField = 'amount';
    public errorsList = {
        errors: '',
        errosCount: 0
    };
    public lenderUwFee = 0;
    public isInputChange: boolean = false;
    public loader = true;
    public lEApproved = false;
    public isMoveToLead = null;
    public lenderCredits = null;
    public addedManually: boolean = null;
    public uwFee: any = null;
    public errorMsg: boolean = false;
    public errorText: string = null;
    public totalClosingCostWithLender = null;
    public estimatedCashToClose = null;
    public loanEstimateDetails = null;
    public readOnlyLenderCredits = false;
    public readOnlySellerCredits = false;
    public readOnlyDeposit = false;
    public showRoundedPercentageOfLoanAmount: boolean = true
    private initialState: any = {};


    get percentageOfLoanAmount() {
        const percentageOfLoanAmount = _.get(this.loanEstimateDetails, 'originationCharges.percentageOfLoanAmount', 0)
        const parsedPercentageOfLoanAmount = parseFloat((percentageOfLoanAmount || 0.0).toFixed(2))
        return this.showRoundedPercentageOfLoanAmount ? parsedPercentageOfLoanAmount : percentageOfLoanAmount
    }
    set percentageOfLoanAmount(value) {
        const floatValue = _.isNaN(parseFloat(value)) ? 0 : parseFloat(value);

        _.set(this.loanEstimateDetails, 'originationCharges.percentageOfLoanAmount', floatValue);
    }

    public get canShowSaveButtons() {
        return this.$userType === 'Wemlo Processor'|| this.canBrokerApprove || this.fromView === 'los';
    }

    public get canBrokerApprove() {
        return this.$userType === 'Broker' && (this.isMoveToLead || this.$route.query.lEnotify || this.$route.query.review);
    }

    public get wemloLoader() {
        return this.$store.state.wemloLoader;
    }
    /***
     * FUNCTIONS
     */

    public async onCalculateExecute() {
      this.$store.commit('SET_WEMLO_LOADER', true);
      Axios.post(BASE_API_URL + 'titleOrder/recalculateFeeWorksheet', {
        loanTxnId: this.$route.query.id,
        userType: this.$userType,
        lEnotify: this.$route.query.lEnotify
      }).then(({data}) => {
        this.setLoanData(data);
        this.$snotify.success('Recalculated successfully');
      }).catch(err => {
        this.$snotify.error(err.message);
      }).finally(() => {
        this.$store.commit('SET_WEMLO_LOADER', false);
      })

    }

    /**
     * Get LE Details
     */
    public async getLoanEstimateDetails() {
        this.$store.commit('SET_WEMLO_LOADER', true);

        try {
            let apiUrl = "titleOrder/getLoanEstimateDetails";

            if (this.fromView == 'los') {
                apiUrl = "titleOrder/getSampleLoanEstimate";
            }

            const response = await Axios.post(
                BASE_API_URL + apiUrl, {
                    loanTxnId: this.$route.query.id,
                    userType: this.$userType
                });

            this.setLoanData(response.data);
            this.setDefaultOriginationCharges();
            this.recalculateForm();

            this.initialState = this.cloneObject(this.getUpdateRequestBody());
        } catch (error) {
            console.log(error);
        } finally {
          this.$store.commit('SET_WEMLO_LOADER', false);
        }
    }

    setLoanData(data) {
        this.loanEstimateDetails = data.loanEstimateData;

        if (!this.loanEstimateDetails) {
            this.errorCallback && this.errorCallback();
            
            // return;
            throw new Error('There is no Loan Estimate Details');
        }

        this.loanEstimateDetails.originationCharges = this.loanEstimateDetails.originationCharges || {};
        this.loanEstimateDetails.originationCharges.uwFee = data.otherData.uwFee;
        this.addedManually = data.otherData.addedManually;
        this.companyDetails = data.otherData.companyDetails;
        this.isDraft = data.otherData.draft;
        this.uwFee = data.otherData.uwFee;
        this.lenderUwFee = data.otherData.lenderUwFee;
        this.lEApproved = data.otherData.approvedOrnot;
        // this.readOnlyLoanEstimate = !!data.otherData.documentUploadedOrNot;
        this.isMoveToLead = data.otherData.isMoveToLead;
        this.lenderCredits =
            this.loanEstimateDetails && this.loanEstimateDetails.lenderCredits ?
            this.loanEstimateDetails.lenderCredits :
            0;
        this.readOnlyLenderCredits = data.otherData.readOnlyLenderCredits;
        this.readOnlySellerCredits = data.otherData.readOnlySellerCredits;
        this.readOnlyDeposit = data.otherData.readOnlyDeposit;
    }

    /**
     * Calculate Prepaid interest in
     */
    calculatePrepaidInterest() {
        try {
            let loanAmount = this.loanEstimateDetails.loanAmount;
            let rate = this.loanEstimateDetails.interestRate;
            if (rate && loanAmount) {
                let intersetPerDay = this.loanEstimateDetails.interestRate / 365;

                this.loanEstimateDetails.prepaids.prepaidIntrest.perDayAmount = intersetPerDay * loanAmount / 100;
            }
        } catch (error) {
            console.log(error);
        }
    }

    get compensationType() {
        return this.borrowerInfo && this.borrowerInfo.loanInformation && this.borrowerInfo.loanInformation.compensationType
    }

    get isBorrowerPaidCompensation() {
        return this.compensationType === 'Borrower Paid Compensation';
    }

    get isLenderPaidCompensation() {
        return this.compensationType === 'Lender Paid Compensation';
    }

    get isFHA() {
        return _.get(this.borrowerInfo, 'loanInformation.productType.ediValue') === '03';
    }

    async updateErrors() {
        this.isInputChange = true;

        const errors = new Set([]);
        let errosCount = 0;
        let $firstError;

        await this.$validator.reset();
        await this.$validator.validate();

        for (let item of this.$validator.errors.items) {
            if (item.scope) {
                if (!$firstError) {
                    $firstError = this.$el.querySelector(`[name=${item.field}]`);
                }

                errors.add(item.scope);
            }
        }

        errosCount = this.$validator.errors.items.length;

        this.errorsList = {
            errors: Array.from(errors).join(', '),
            errosCount: errosCount
        }
        return $firstError;
    }

    public async getBorrowerInfo() {
        try {
            this.$store.commit('SET_WEMLO_LOADER', true);
            const response = await Axios.post(
                BASE_API_URL + "borrower/getBorrowerInfo", {
                    loanTxnId: this.$route.query.id,
                    userId: this.$userId
                }
            );
            if (response.status == 200) {
                this.borrowerInfo = response.data;
            }
        } catch (error) {
            console.log(error);
        } finally {
          this.$store.commit('SET_WEMLO_LOADER', false);
        }
    }

    public getShortname(compensationType) {
        if (!compensationType) return '';
        let list = compensationType.split(' ');
        list = list.map(i => i[0]);
        let name = list.join('');
        if (name) {
            return `(${name})`
        }
    }

    public getUpdateRequestBody() {
        let prepaidItems = this.getTotal(
            this.loanEstimateDetails.prepaids,
            "amount"
        );

        let totalB = this.getSum(this.loanEstimateDetails.cannotShopFor, 'amount');
        let totalC = this.getTotalFees();
        let totalE = this.getTotal(this.loanEstimateDetails.taxesOtherGovtFees);
        let totalG = this.getTotal(this.loanEstimateDetails.escrowPaymentAtClosing, "amount");
        let uwFee = this.loanEstimateDetails.originationCharges.uwFee;
        //Closing Cost = (B+C+E+G+uwFeeIfNotWaived)
        let totalClosingCost = totalB + totalC + totalE + totalG + uwFee;

        return {
            loanTxnId: this.$route.query.id,
            loanEstimateDetails: this.loanEstimateDetails,
            estimatedCashToClose: this.calculateEstimatedCashToClose(),
            addedBy: this.$store.state.sessionObject.userId,
            totalClosingCost,
            prepaidItems,
            isInputChange: this.isInputChange,
            userType: this.$userType,
            userId: this.$userId,
            lEnotify: this.$route.query.lEnotify
        }
    }

    /**
     * Overriding the submit function
     */
    public async submit(checkUnsaved = false) {
        try {
            /**
             * Prevent saving empty data
             */
            if (!this.loanEstimateDetails) {
                return false;
            }

            this.errorMsg = false;
            this.$store.commit('SET_WEMLO_LOADER', true);

            if (this.$userType == 'Wemlo Processor') {
                this.lEApproved = true;
            }
            
            const firstError = await this.updateErrors();
            if (this.errorsList.errosCount) {
                this.errorMsg = true;
                firstError && firstError.scrollIntoView(false);
            }

            let formValid = await this.$validator.validate();
            if (this.fromView !== 'los' && (!formValid || !this.lEApproved)) {
                this.errorText = !this.lEApproved ? "Please approve the Fee Worksheet to proceed" : 'Please review the Fee Worksheet to proceed';

                this.$snotify.error(this.errorText);

                this.errorMsg = true;
                this.$store.commit('SET_WEMLO_LOADER', false);
                return false;
            }

            const requestBody = this.getUpdateRequestBody();
            const unsavedFields = this.difference(requestBody, this.initialState);
            if (unsavedFields && checkUnsaved) {
                this.$store.commit('SET_WEMLO_LOADER', false);
                return { unsavedFields };
            }

            if (this.isInputChange) {
                const response = await Axios.post(
                    BASE_API_URL + "titleOrder/saveLoanEstimateDetails", requestBody);

                if (this.saveCallback) {
                    this.saveCallback();
                }

                await this.getLoanEstimateDetails();
            }

            return true;
        } catch (error) {
            console.log(error);

            this.$snotify.error("Something went wrong! Please try after sometime.");

            /**
             * Must return false if throw some error
             */
            return false;
        } finally {
          this.$store.commit('SET_WEMLO_LOADER', false);
        }
    }

    public async downloadPdf() {
        await this.submit()

        if (!this.loanEstimateDetails.loanEstimatePdfPath) {
            this.$snotify.error("There is no Fee Worksheet to download, please contact the Broker.");
            return;
        }
        
        const pdfPath = this.loanEstimateDetails.loanEstimatePdfPath
        const zipName = this.$route.query.id ? this.$route.query.id : "Fee_Worksheet";

        const dataObj = {
            check: true,
            data: [`${pdfPath}`],
            name: zipName
        }

        const response = await Axios.post(
            `${BASE_API_URL}common/downloadFileS3`,
            dataObj, {
                responseType: 'blob'
            }
        )

        saveAs(response.data, `${zipName}.zip`)
    }

    /**
     * To Calculate Total fees for services you can shop for
     */
    public getTotalFees() {
        let amount: any = 0;
        amount += this.getSum(
            this.loanEstimateDetails.canShopFor.details,
            "totalAmount"
        );
        return amount;
    }

    public openModal() {
        this.$modal.show("addFeesDetails");
    }

    public closeAddFieldsModal() {
        this.pathToAddFields = null;
        this.resetAddFields();
        this.$modal.hide("addNewFields");
    }

    public saveAdditionalFields() {
        let validFields = this.fieldsToAdd.filter(field => (field.type == 'other' ? field.other : field.type));
        validFields.forEach(field => {
            if (field.other) {
                field.type = field.other;
            }
        })
        this.pathToAddFields = this.pathToAddFields.push(...validFields);
        this.closeAddFieldsModal();
    }

    /**
     * Open a modal to add more fields
     */
    public openAddFieldsModal(path, section, name, amountField) {
        this.currentSection = name;
        this.pathToAddFields = path;
        this.amountField = amountField;
        this.resetAddFields();
        this.sectionFields = LOAN_ESTIMATE_OTHER_FIELDS[section];
        this.$modal.show("addNewFields");
    }

    public resetAddFields() {
        this.fieldsToAdd = [{
            type: null,
            [this.amountField]: 0
        }];
    }

    /**
     * To push fees in SettlementTitle  (Section C)
     */
    public async addFeesForTitleOrder() {
        try {
            let formValid = await this.$validator.validateAll();
            if (!formValid) return;
            if (this.loanEstimateDetails.canShopFor.type == "SettlementTitle") {
                this.loanEstimateDetails.canShopFor.details.push(this.details);
            }
            this.$modal.hide("addFeesDetails");
            this.details = {
                buyerAmount: null,
                sellerAmount: null,
                totalAmount: null,
                type: null,
                feeDetails: []
            };
        } catch (error) {
            console.log(error);
        }
    }

    /**
     * To delete SettlementTitle sub fees (Section C)
     */
    public deleteSelectedCompany(index) {
        try {
            this.loanEstimateDetails.canShopFor.splice(index, 1);
            this.isInputChange = true;
        } catch (error) {
            console.log(error);
        }
    }

    /**
     * To calculate fees
     */

    public getSum(arr ? : any, variable ? : string) {
        arr = arr.filter(item =>
            variable ?
            this.$lodash.get(item, variable) :
            item != undefined && variable ?
            this.$lodash.get(item, variable) :
            item != "" && variable ?
            this.$lodash.get(item, variable) :
            item != null && variable ?
            this.$lodash.get(item, variable) :
            item
        );
        arr = arr.map(a => (variable ? this.$lodash.get(a, variable) : a));
        arr = arr.reduce(function (a, b) {
            return parseFloat(a) + parseFloat(b);
        }, 0);
        arr = +parseFloat(arr);
        return arr;
    }

    onChangeOriginationValue() {
        this.isInputChange = true;
        this.loanEstimateDetails.originationCharges.percentageOfLoanAmount = this.getOrignationPercent();
    }

    onChangeOriginationPercentage() {
        this.isInputChange = true;
        this.loanEstimateDetails.originationCharges.chargesOfLoanAmount = this.getOrignationAmount();
    }
    
    onChangeOriginationWaive() {
        if (this.loanEstimateDetails.originationCharges.waive) {
            this.loanEstimateDetails.originationCharges.uwFee = 0;
        } else {
            this.loanEstimateDetails.originationCharges.uwFee = this.loanEstimateDetails.originationCharges.uwFee || this.lenderUwFee;
        }

        this.$nextTick(() => this.updateErrors());
    }

    /**
     * To calculate Origination charges
     */
    public getOrignationPercent(val = null) {
        let amount: any = 0;
        if (
            this.loanEstimateDetails &&
            this.loanEstimateDetails.originationCharges &&
            this.loanEstimateDetails.originationCharges.chargesOfLoanAmount &&
            this.loanEstimateDetails.loanAmount
        ) {
            amount =
                (this.loanEstimateDetails.originationCharges.chargesOfLoanAmount
                 / parseFloat(val || this.loanEstimateDetails.loanAmount)) *
                100;
        }
        return amount;
    }

    /***
     * get precission float value
     */
    public getPrecissionValue(val = 0, precission = 2) {
        return !isNaN(val) ? val.toFixed(precission) : val;
    }

    /**
     * To calculate Origination charges
     */
    public getOrignationAmount() {
        let amount: any = 0;
        if (
            this.loanEstimateDetails &&
            this.loanEstimateDetails.originationCharges &&
            this.loanEstimateDetails.originationCharges.percentageOfLoanAmount &&
            this.loanEstimateDetails.loanAmount
        ) {
            amount =
                (parseFloat(
                        this.loanEstimateDetails.originationCharges.percentageOfLoanAmount
                    ) /
                    100) *
                parseFloat(this.loanEstimateDetails.loanAmount);
            amount = amount.toFixed(2);
        }
        return parseFloat(amount);
    }

    public getOrignationChargesTotal() {
        let originationChargesTotal = this.getOrignationAmount() +
            parseFloat(this.loanEstimateDetails.originationCharges.uwFee) +
            parseFloat(this.getSum(this.loanEstimateDetails.originationCharges.otherCharges, 'amount').toFixed(2))
        return originationChargesTotal.toFixed(2)
    }

    /**
     * To calculate total sum
     */

    getTotal(data, origin = null) {
        let total: any = 0;
        if (typeof data == "object") {
            for (let value in data) {
                total =
                    total +
                    (origin ? parseFloat(data[value][origin]) : parseFloat(data[value]));
            }
            total = total.toFixed(2);
        }
        return Math.round(total);
    }

    /**
     * To calculate Other cost fees
     */

    public getOtherCost(recalc) {
        let getOtherCost =
            this.getTotal(this.loanEstimateDetails.taxesOtherGovtFees) +
            this.getTotal(this.loanEstimateDetails.prepaids, "amount") +
            this.getTotal(this.loanEstimateDetails.escrowPaymentAtClosing, "amount") +
            this.getSum(this.loanEstimateDetails.other, "amount");

        return Math.round(getOtherCost.toFixed(2));
    }

    /**
     * To calculate CLosing cost charges
     */

    getTotalClosingCost() {
        if (this.loanEstimateDetails) {
            let originationAmount = this.getOrignationAmount();
            let otherOriginationAmount = this.getSum(
                this.loanEstimateDetails.originationCharges.otherCharges,
                "amount"
            );
            let cannotShopFor = this.getSum(
                this.loanEstimateDetails.cannotShopFor,
                "amount"
            );
            let canShopFor = this.getSum(
                this.loanEstimateDetails.canShopFor.details,
                "totalAmount"
            );
            let uwFee =
                this.loanEstimateDetails.originationCharges &&
                this.loanEstimateDetails.originationCharges.uwFee ?
                this.loanEstimateDetails.originationCharges.uwFee :
                0;
            let totalLoanCost =
                originationAmount +
                otherOriginationAmount +
                cannotShopFor +
                canShopFor +
                uwFee;
            let totalOtherCost = String(this.getOtherCost(true));

            return Math.round(parseFloat(totalLoanCost) + parseFloat(totalOtherCost));
        }
        return 0;
    }
    /**
     * 
     */
    @Watch('loanEstimateDetails', {
        immediate: true,
        deep: true
    })
    recalculateForm() {
        if (this.loanEstimateDetails) {
            this.calculateClosingCostWithLender(this.loanEstimateDetails.lenderCredits);
            this.calculateEstimatedCashToClose();
            this.setPerpaidAmount();
            this.calculatePrepaidInterest();
            this.updateErrors();
        }
    }

    calculateClosingCostWithLender(lenderCredits) {
        try {
            this.totalClosingCostWithLender = this.getTotalClosingCost() - lenderCredits;
        } catch (error) {
            console.log(error);
        }
    }

    /**
     * 
     * Total Loan Costs(A+B+C)
     * */
    getLoanCosts(recalc) {
        let originationAmount = this.getOrignationAmount();
        let cost:any = parseFloat((
            originationAmount +
            this.loanEstimateDetails.originationCharges.uwFee +
            this.getSum(this.loanEstimateDetails.originationCharges.otherCharges, 'amount') +
            this.getSum(this.loanEstimateDetails.cannotShopFor, 'amount') +
            this.getTotalFees())).toFixed(2);
        cost = parseFloat(cost)
        return Math.round(cost);
    }

    calculateEstimatedCashToClose() {
        this.estimatedCashToClose =
            this.totalClosingCostWithLender // Total Closing Costs(J)
            + this.loanEstimateDetails.fundsFromBorrower // Down payments/Funds From Borrower
            + this.loanEstimateDetails.fundsForBorrower // Funds For Borrower
            - this.loanEstimateDetails.sellerCredits // Seller credits
            - this.loanEstimateDetails.deposit // Deposit
            - this.loanEstimateDetails.estimatedClosingCost;

        return this.estimatedCashToClose;
    }

    // calculateHomeOwnerInsurance(data) {
    //   try {
    //     console.log(data, "kkkkkkk");
    //     return;
    //     if (
    //       (this.loanEstimateDetails &&
    //         this.loanEstimateDetails.escrowPaymentAtClosing &&
    //         this.loanEstimateDetails.escrowPaymentAtClosing.ownersInsurance &&
    //         this.loanEstimateDetails.escrowPaymentAtClosing.ownersInsurance
    //           .perMonthAmount) ||
    //       (this.loanEstimateDetails &&
    //         this.loanEstimateDetails.prepaids &&
    //         this.loanEstimateDetails.prepaids.ownersInsurancePremium &&
    //         this.loanEstimateDetails.prepaids.ownersInsurancePremium.month)
    //     ) {
    //       this.loanEstimateDetails.prepaids.ownersInsurancePremium.amount =
    //         parseFloat(data) *
    //         parseFloat(
    //           this.loanEstimateDetails.escrowPaymentAtClosing.ownersInsurance
    //             .perMonthAmount
    //         );
    //     }
    //   } catch (error) {
    //     console.log(error);
    //   }
    // }

    calculateHomeOwnerInsurance(data, type) {
        try {
            if (data && type == "month") {
                this.loanEstimateDetails.prepaids.ownersInsurancePremium.amount =
                    parseFloat(data) *
                    parseFloat(
                        this.loanEstimateDetails.escrowPaymentAtClosing.ownersInsurance
                        .perMonthAmount
                    );
            } else {
                this.loanEstimateDetails.prepaids.ownersInsurancePremium.amount =
                    parseFloat(data) * parseFloat(this.loanEstimateDetails.prepaids.ownersInsurancePremium.month);
            }
        } catch (error) {
            console.log(error);
        }
    }

    calculatePropertyTaxes(data, type) {
        try {
            if (data && type == "month") {
                this.loanEstimateDetails.prepaids.propertyTaxes.amount =
                    parseFloat(data) *
                    parseFloat(this.loanEstimateDetails.escrowPaymentAtClosing.propertyTaxes.perMonthAmount);
            } else {
                this.loanEstimateDetails.prepaids.propertyTaxes.amount =
                    parseFloat(data) * parseFloat(this.loanEstimateDetails.prepaids.propertyTaxes.month);
            }
        } catch (error) {
            console.log(error);
        }
    }

    calculateMortageAmount(data, type) {
        try {
            if (data && type == "month") {
                this.loanEstimateDetails.prepaids.mortgageInsurancePremium.amount =
                    parseFloat(data) *
                    parseFloat(
                        this.loanEstimateDetails.escrowPaymentAtClosing.mortgageInsurance.perMonthAmount
                    );
            } else {
                this.loanEstimateDetails.prepaids.mortgageInsurancePremium.amount =
                    parseFloat(data) * parseFloat(this.loanEstimateDetails.prepaids.mortgageInsurancePremium.month);
            }
        } catch (error) {
            console.log(error);
        }
    }

    /**
     * To calculate Prepaid amount
     */

    // setPerpaidAmount() {
    //   let totalAmount: any = 0;
    //   if (
    //     this.loanEstimateDetails &&
    //     this.loanEstimateDetails.prepaids.prepaidIntrest.perDayAmount &&
    //     this.loanEstimateDetails.prepaids.prepaidIntrest.days
    //   ) {
    //     totalAmount =
    //       parseFloat(
    //         this.loanEstimateDetails.prepaids.prepaidIntrest.perDayAmount
    //       ) * parseFloat(this.loanEstimateDetails.prepaids.prepaidIntrest.days);
    //     if (this.loanEstimateDetails.prepaids.prepaidIntrest.interestRate)
    //       totalAmount =
    //         totalAmount *
    //         (parseFloat(
    //           this.loanEstimateDetails.prepaids.prepaidIntrest.interestRate
    //         ) /
    //           100);
    //     totalAmount = parseFloat(totalAmount.toFixed(2));
    //   }
    //   this.loanEstimateDetails.prepaids.prepaidIntrest.amount = totalAmount;
    // }

    setPerpaidAmount() {
        let totalAmount: any = 0;
        if (
            this.loanEstimateDetails &&
            this.loanEstimateDetails.prepaids.prepaidIntrest.perDayAmount &&
            this.loanEstimateDetails.prepaids.prepaidIntrest.days
        ) {
            totalAmount =
                parseFloat(
                    this.loanEstimateDetails.prepaids.prepaidIntrest.perDayAmount
                ) * parseFloat(this.loanEstimateDetails.prepaids.prepaidIntrest.days);
            totalAmount = parseFloat(totalAmount.toFixed(2));
        }
        this.loanEstimateDetails.prepaids.prepaidIntrest.amount = totalAmount;
    }

    /**
     * To calculate Escrow payment
     */

    setEscrowPayment(origin) {
        if (this.loanEstimateDetails) {
            this.isInputChange = true;
            this.loanEstimateDetails.escrowPaymentAtClosing[origin].amount =
                this.loanEstimateDetails.escrowPaymentAtClosing[origin]
                .perMonthAmount &&
                this.loanEstimateDetails.escrowPaymentAtClosing[origin].month ?
                parseFloat(
                    this.loanEstimateDetails.escrowPaymentAtClosing[origin]
                    .perMonthAmount
                ) *
                parseFloat(
                    this.loanEstimateDetails.escrowPaymentAtClosing[origin].month
                ) :
                0;
        }
    }

    /**
     * To hide notification
     */
    hideNotification() {
        this.addedManually = false;
    }

    setDefaultOriginationCharges() {
        if (!this.loanEstimateDetails) {
            return;
        }

        if (this.compensationType == 'Lender Paid Compensation') {
            this.loanEstimateDetails.originationCharges.percentageOfLoanAmount = 0;
            this.loanEstimateDetails.originationCharges.chargesOfLoanAmount = 0;
        }

        if (this.loanEstimateDetails.originationCharges.waive) {
            this.loanEstimateDetails.originationCharges.uwFee = 0;
        } else if (!this.loanEstimateDetails.originationCharges.uwFee) {
            this.loanEstimateDetails.originationCharges.uwFee = this.lenderUwFee;
        }

        this.onChangeOriginationPercentage();
    }

    public async mounted() {
        await this.getBorrowerInfo();
        await this.getLoanEstimateDetails();

        this.$store.commit('SET_WEMLO_LOADER', true);
        await this.updateErrors();
        this.$store.commit('SET_WEMLO_LOADER', false);
    }

    public validateInputNumber(event) {
        const charCode = (event.which) ? event.which : event.keyCode
        if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
            event.preventDefault()
        } else {
            return true;
        }
    }
}
